import React, { useEffect, useState, useRef } from 'react';

export const Card = (props) => {
  const card = useRef(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const rect = card.current.getBoundingClientRect();
      setVisible(rect.top - window.innerHeight < -50);
    };

    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <section>
      <div ref={card} className={`card${props.left ? ' left' : ''}${props.right ? ' right' : ''}${visible ? ' show' : ' hide'}`}>
        <h2>{props.title}</h2>
        <img src={props.image} alt={props.title} />
        <p className='tagline'>{props.tagline}</p>
        <p>{props.desc}</p>
        <p><a href={props.link} target='_blank' rel='noopener noreferrer'>{props.linkText}</a></p>
      </div>
    </section>
  );
}
