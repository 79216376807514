import React, { useEffect, useState } from 'react';

const SPEED = 180;

export const Background = () => {
  const [scrollPct, setScrollPct] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      const pct = window.scrollY / (document.body.scrollHeight - window.innerHeight);
      setScrollPct(pct);
    };

    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return <div
    className='background'
    style={{
      background: `linear-gradient(147deg,
        #76ecff ${20 - scrollPct * SPEED}%,
        #b3ff9f ${90 - scrollPct * SPEED}%,
        #fbd95e ${130 - scrollPct * SPEED}%,
        #ef8446 ${200 - scrollPct * SPEED}%,
        #02022f ${280 - scrollPct * SPEED}%
      `}}
  />;
};


/*

 background: `linear-gradient(0deg,
      #1584c5 ${20 - scrollPct * SPEED}%,
      #bdffe5 ${90 - scrollPct * SPEED}%,
      #ef8446 ${170 - scrollPct * SPEED}%,
      #2311b7 ${210 - scrollPct * SPEED}%,
      #02022f ${280 - scrollPct * SPEED}%

*/