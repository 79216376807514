import React from 'react';
import './main.sass';
import { Card } from './Card';
import { ReactComponent as TwitterIcon } from './images/twitter.svg';
import { ReactComponent as MailIcon } from './images/mail.svg';
import { ReactComponent as GitHubIcon } from './images/github.svg';

import carta from './images/Carta.png';
import checkers from './images/checkers.png';
import dominoes from './images/DominoDash.png';
import ecs from './images/ecs.jpg';
import madCow from './images/MadCow.jpg';
import packetLoss from './images/packetLoss.jpg';
import postHaste from './images/PostHaste.png';
import stealth from './images/Stealth.jpg';
import towerDefence from './images/TowerDefence.jpg';
import { Background } from './Background';

function App() {
  return (
    <div>
      <Background />
      <section>
        <h1 className='main'>Callum Neideck</h1>
      </section>
      <section>
        <div className='about-holder'>
          <div className='card about right'>
            <h2>About Me</h2>
            <p>I have completed a Bachelor of Computer Science (majoring in Games Development) at Swinburne University and am currently working at mod.io as a frontend developer. I have combined my passion for programming and video games to focus on games development. I spend a lot of my spare time making games, experimenting with new ideas and programming small tools to make tasks easier. Other activities I enjoy are hockey, running and mountain biking.</p>
          </div>
        </div>
      </section>
      <section>
        <h1>Projects</h1>
      </section>
      {Carta}
      {ECS}
      {Checkers}
      {MadCow}
      {TowerDefence}
      {PostHaste}
      {DominoDash}
      {Stealth}
      {PacketLoss}
      <section>
        <div className='contact'>
          <a href='https://twitter.com/calneideck' target='_blank' rel='noreferrer noopener'><TwitterIcon /></a>
          <a href='mailto:cal_neideck@hotmail.com'><MailIcon /></a>
          <a href='https://github.com/Calneideck' target='_blank' rel='noreferrer noopener'><GitHubIcon /></a>
          <p>Callum Neideck. 2020</p>
        </div>
      </section>
    </div>
  );
}

export default App;

const Carta = <Card
  left
  title='Carta'
  image={carta}
  tagline='Carta is a puzzle platformer where the player must manipulate the paper world in order to help Carta reclaim their lost map pieces and return home.'
  desc='Carta was my final-year university Capstone project. I worked alongside a team of 2 programmers and 5 designers to create this cute little platformer. Despite being a programmer, I actually did a lot of design work too as I experimented with a lot of different gameplay mechanics. It was a great experience working in a larger team and at the end of the year we exhibited our game at PAX Australia. We were also lucky enough to be the winners of the GCAP Student Showcase for 2017.'
  link='https://carta.itch.io/carta'
  linkText='View on Itch.io'
/>;

const ECS = <Card
  title='ECS Tower Defence'
  image={ecs}
  tagline='Build many towers to defeat waves of enemies.'
  desc={`A proof of concept game that utilises Unity3D's new DOTS system. The game is super performant even with hundreds of towers firing at hundreds of enemies with a very rapid rate-of-fire.`}
  link='https://github.com/Calneideck/ECS_Tower_Defence'
  linkText='View on GitHub'
/>;

const TowerDefence = <Card
  right
  title='Tower Defence'
  image={towerDefence}
  tagline='Build towers to eliminate waves of enemy units before they reach your end zone and take all of your lives.'
  desc='This is the first proper PC game that I made. I created all of the 3D models myself, learning how to use Blender through online tutorials. This game features multithreaded pathfinding so each enemy unit can find its way in a performant manner.'
  link='https://github.com/Calneideck/TowerDefence/'
  linkText='View on GitHub'
/>;

const Checkers = <Card
  right
  title='Checkers'
  image={checkers}
  tagline='Asynchronous online multiplayer checkers'
  desc={`I made multiplayer checkers because I wanted to make a proper multiplayer game. A NodeJS server runs on an AWS EC2 instance and every client communicates with that.  The game logic is implemented on the server so cheating by sending fake data is not possible. Each game is stored in a database so that either player can quit the game and come back to it later (hence the 'asynchronous' title). All of the network code is using low-level network sockets (as opposed to Unity's built-in solution).`}
  link='https://play.google.com/store/apps/details?id=com.callumneideck.checkers'
  linkText='View on Google Play Store'
/>;

const PostHaste = <Card
  left
  title='Post Haste'
  image={postHaste}
  tagline='Pickup packages from the depot in your flying van and deliver them to the correct houses. Make as many deliveries as you can before time runs out!'
  desc='I developed Post Haste with a small team during Global Game Jam 2016. It was my first experience of working in a team and despite the fact that the game itself is not that complex, I am proud of what we managed to create in a 48 hour period.'
  link='http://globalgamejam.org/2016/games/post-haste'
  linkText='View Global Game Jam Page'
/>;

const MadCow = <Card
  left
  title='Mad Cow'
  image={madCow}
  tagline='Mad cow disease has broken out in a small farm. Play as either a farmer trying to put down the affected cows before they infect the herd; or as a mad cow, fighting for its life.'
  desc='My first mobile game. Made with Unity and published to the Google Play Store. I am really happy with how it turned out.'
  link='https://play.google.com/store/apps/details?id=com.CallumNeideck.MadCow'
  linkText='View Google Play Store Page'
/>;

const DominoDash = <Card
  title='Domino Dash'
  image={dominoes}
  tagline='Line up dominoes as they head off the screen. Place as many as you can before they get away from you!'
  desc='A mobile game that I created and released in a small amount of time. It was a challenge to ensure there is always a valid move available, when the player places a domino out of order, due to there being 2 with the same number on top, but I managed to figure it out by re-ordering all of the dominoes below the selected one.'
  link='https://play.google.com/store/apps/details?id=com.CallumNeideck.DominoDash'
  linkText='View Google Play Store page'
/>;

const Stealth = <Card
  right
  title='Top-Down Stealth Shooter'
  image={stealth}
  tagline='Evade the guards and security bots. Grab the intel without getting caught.'
  desc={`A proof-of-concept game for a unit titled 'AI for Games'. This project demonstrates pathfinding, finite state machines, behaviour trees, swarm/flocking behaviour and goal oriented action behaviour.`}
  link='https://github.com/Calneideck/AI'
  linkText='View on GitHub'
/>;

const PacketLoss = <Card
  left
  title='packetLoss'
  image={packetLoss}
  tagline={`Waves of swarming data packets must be routed to the centre, ensuring everyone’s data is delivered in a timely manner. Lose too many packets and it's game over.`}
  desc='packetLoss was created at Global Game Jam 2017. I jammed with some workmates who had never worked on a game before so it was a lot of fun and we all learnt a lot. The gameplay is very minimalistic but we had some good artists so I think the visuals are quite striking.'
  link='https://globalgamejam.org/2017/games/packetloss'
  linkText='View Global Game Jam page'
/>;
